import Vue from 'vue'
import Router from 'vue-router'
import { normalizeURL, decode } from 'ufo'
import { interopDefault } from './utils'
import scrollBehavior from './router.scrollBehavior.js'

const _624be3fd = () => interopDefault(import('../pages/check-email.vue' /* webpackChunkName: "pages/check-email" */))
const _beade362 = () => interopDefault(import('../pages/login.vue' /* webpackChunkName: "pages/login" */))
const _7cec77d8 = () => interopDefault(import('../pages/logout.vue' /* webpackChunkName: "pages/logout" */))
const _bd4d3526 = () => interopDefault(import('../pages/medications/index.vue' /* webpackChunkName: "pages/medications/index" */))
const _21e777da = () => interopDefault(import('../pages/password-updated.vue' /* webpackChunkName: "pages/password-updated" */))
const _08724cde = () => interopDefault(import('../pages/paylinks/index.vue' /* webpackChunkName: "pages/paylinks/index" */))
const _3bd7a3c2 = () => interopDefault(import('../pages/prescriptions/index.vue' /* webpackChunkName: "pages/prescriptions/index" */))
const _10ed97a6 = () => interopDefault(import('../pages/register.vue' /* webpackChunkName: "pages/register" */))
const _d26c6fe8 = () => interopDefault(import('../pages/registered.vue' /* webpackChunkName: "pages/registered" */))
const _5516fbed = () => interopDefault(import('../pages/resend-confirmation.vue' /* webpackChunkName: "pages/resend-confirmation" */))
const _cce8733a = () => interopDefault(import('../pages/reset-password.vue' /* webpackChunkName: "pages/reset-password" */))
const _7d2d01ad = () => interopDefault(import('../pages/settings.vue' /* webpackChunkName: "pages/settings" */))
const _79a263f6 = () => interopDefault(import('../pages/settings/close-account.vue' /* webpackChunkName: "pages/settings/close-account" */))
const _22df1d06 = () => interopDefault(import('../pages/settings/export.vue' /* webpackChunkName: "pages/settings/export" */))
const _33f0cdda = () => interopDefault(import('../pages/settings/hospitals/index.vue' /* webpackChunkName: "pages/settings/hospitals/index" */))
const _b7a2fe88 = () => interopDefault(import('../pages/settings/options.vue' /* webpackChunkName: "pages/settings/options" */))
const _3cf21a63 = () => interopDefault(import('../pages/settings/pharmacies.vue' /* webpackChunkName: "pages/settings/pharmacies" */))
const _ec7ad7dc = () => interopDefault(import('../pages/settings/security.vue' /* webpackChunkName: "pages/settings/security" */))
const _5bf5f81d = () => interopDefault(import('../pages/settings/user.vue' /* webpackChunkName: "pages/settings/user" */))
const _9e3b58b0 = () => interopDefault(import('../pages/settings/hospitals/new.vue' /* webpackChunkName: "pages/settings/hospitals/new" */))
const _f0c92876 = () => interopDefault(import('../pages/settings/hospitals/_id/index.vue' /* webpackChunkName: "pages/settings/hospitals/_id/index" */))
const _4451aae7 = () => interopDefault(import('../pages/settings/hospitals/_id/edit.vue' /* webpackChunkName: "pages/settings/hospitals/_id/edit" */))
const _3b273374 = () => interopDefault(import('../pages/embed/rx.vue' /* webpackChunkName: "pages/embed/rx" */))
const _2e54f8e0 = () => interopDefault(import('../pages/prescriptions/new.vue' /* webpackChunkName: "pages/prescriptions/new" */))
const _6a03f877 = () => interopDefault(import('../pages/redirect/new.vue' /* webpackChunkName: "pages/redirect/new" */))
const _51fd2990 = () => interopDefault(import('../pages/index.vue' /* webpackChunkName: "pages/index" */))
const _06bbab96 = () => interopDefault(import('../pages/paylinks/_slug.vue' /* webpackChunkName: "pages/paylinks/_slug" */))
const _747f96f9 = () => interopDefault(import('../pages/prescriptions/_signature.vue' /* webpackChunkName: "pages/prescriptions/_signature" */))
const _6c1f785b = () => interopDefault(import('../pages/new-password/_uid/_token.vue' /* webpackChunkName: "pages/new-password/_uid/_token" */))

const emptyFn = () => {}

Vue.use(Router)

export const routerOptions = {
  mode: 'history',
  base: '/',
  linkActiveClass: 'nuxt-link-active',
  linkExactActiveClass: 'nuxt-link-exact-active',
  scrollBehavior,

  routes: [{
    path: "/check-email",
    component: _624be3fd,
    name: "check-email"
  }, {
    path: "/login",
    component: _beade362,
    name: "login"
  }, {
    path: "/logout",
    component: _7cec77d8,
    name: "logout"
  }, {
    path: "/medications",
    component: _bd4d3526,
    name: "medications"
  }, {
    path: "/password-updated",
    component: _21e777da,
    name: "password-updated"
  }, {
    path: "/paylinks",
    component: _08724cde,
    name: "paylinks"
  }, {
    path: "/prescriptions",
    component: _3bd7a3c2,
    name: "prescriptions"
  }, {
    path: "/register",
    component: _10ed97a6,
    name: "register"
  }, {
    path: "/registered",
    component: _d26c6fe8,
    name: "registered"
  }, {
    path: "/resend-confirmation",
    component: _5516fbed,
    name: "resend-confirmation"
  }, {
    path: "/reset-password",
    component: _cce8733a,
    name: "reset-password"
  }, {
    path: "/settings",
    component: _7d2d01ad,
    name: "settings",
    children: [{
      path: "close-account",
      component: _79a263f6,
      name: "settings-close-account"
    }, {
      path: "export",
      component: _22df1d06,
      name: "settings-export"
    }, {
      path: "hospitals",
      component: _33f0cdda,
      name: "settings-hospitals"
    }, {
      path: "options",
      component: _b7a2fe88,
      name: "settings-options"
    }, {
      path: "pharmacies",
      component: _3cf21a63,
      name: "settings-pharmacies"
    }, {
      path: "security",
      component: _ec7ad7dc,
      name: "settings-security"
    }, {
      path: "user",
      component: _5bf5f81d,
      name: "settings-user"
    }, {
      path: "hospitals/new",
      component: _9e3b58b0,
      name: "settings-hospitals-new"
    }, {
      path: "hospitals/:id",
      component: _f0c92876,
      name: "settings-hospitals-id"
    }, {
      path: "hospitals/:id/edit",
      component: _4451aae7,
      name: "settings-hospitals-id-edit"
    }]
  }, {
    path: "/embed/rx",
    component: _3b273374,
    name: "embed-rx"
  }, {
    path: "/prescriptions/new",
    component: _2e54f8e0,
    name: "prescriptions-new"
  }, {
    path: "/redirect/new",
    component: _6a03f877,
    name: "redirect-new"
  }, {
    path: "/",
    component: _51fd2990,
    name: "index"
  }, {
    path: "/paylinks/:slug",
    component: _06bbab96,
    name: "paylinks-slug"
  }, {
    path: "/prescriptions/:signature",
    component: _747f96f9,
    name: "prescriptions-signature"
  }, {
    path: "/new-password/:uid?/:token?",
    component: _6c1f785b,
    name: "new-password-uid-token"
  }],

  fallback: false
}

export function createRouter (ssrContext, config) {
  const base = (config._app && config._app.basePath) || routerOptions.base
  const router = new Router({ ...routerOptions, base  })

  // TODO: remove in Nuxt 3
  const originalPush = router.push
  router.push = function push (location, onComplete = emptyFn, onAbort) {
    return originalPush.call(this, location, onComplete, onAbort)
  }

  const resolve = router.resolve.bind(router)
  router.resolve = (to, current, append) => {
    if (typeof to === 'string') {
      to = normalizeURL(to)
    }
    return resolve(to, current, append)
  }

  return router
}
